import React, { useEffect, useState } from "react"

// Components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
  FirstScreen,
  WhyUs,
  StagesOfWork,
  StagesOfWorkSlider,
  CompletedProjects,
  SolarPowerPlants,
  SolarPowerPlantsSmall,
  OurClients,
} from "../components/HomePage"
import { FormOrder } from '../components/Global'
// yaml
import YAMLGlobal from "../content/ua/global.yaml"
import YAMLHome from "../content/ua/home.yaml"
import { graphql, useStaticQuery } from "gatsby"

const IndexPage = ({location}) => {

const data = useStaticQuery(graphql`
    query {
        seo: directusHomeSeo {
            title_ua
            description_ua
        }
        allDirectusWhyIsList {
            edges {
                node {
                    advantage_1_ua
                    advantage_2_ua
                    advantage_3_ua
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 918) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        },
        allDirectusWhyUsIconsRu {
            edges {
                node {
                    description_ua
                    icon {
                        localFile {
                            publicURL
                        }
                    }
                    id
                }
            }
        },
        projects: allDirectusOurProject(filter: {show_on_main: {eq: true}}, limit: 4, sort: {fields: id}) {
            edges {
                node {
                    id
                    title_photo {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 588) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    slug
                    title_ua
                }
            }
        },
        slider: allDirectusMainSlide(sort: {fields: sort}) {
            edges {
                node {
                    id
                    button_link
                    button_text_ua
                    subtitle_ua
                    title_ua
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1703) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        },
        solar: allDirectusMainPrice {
            edges {
                node {
                    auto_list_ua
                    auto_list_minus_ua
                    auto_price
                    auto_title_ua
                    econom_list_ua
                    econom_list_minus_ua
                    econom_price
                    econom_title_ua
                    reserve_list_ua
                    reserve_price
                    reserve_title_ua
                    reverse_list_minus_ua
                }
            }
        },
        clients: allDirectusHomeTestimonialsRu {
            edges {
                node {
                    project
                    id
                    name
                    slug
                    link
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 628) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        },
        stages: allDirectusStepsWorkRu(sort: {fields: sort}) {
            edges {
                node {
                    id
                    list_ua
                    sort
                    title_ua
                    image {
                        localFile {
                            childImageSharp {
                                fluid {
                                    base64
                                    tracedSVG
                                    srcWebp
                                    srcSetWebp
                                    originalImg
                                    originalName
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`)

  const [stages, setStages] = useState(null);
  
  const indexLocal = {
    langBase: "/ua",
    YAMLGlobal,
    YAMLContent: YAMLHome
  }

  function toggleStages() {
    if (document.documentElement.clientWidth <= 992) {
      setStages(<StagesOfWorkSlider stages={data.stages.edges}/>)
    } else {
      setStages(<StagesOfWork stages={data.stages.edges}/>)
    }
  }
  
  useEffect(() => {
    toggleStages();

    window.addEventListener('resize', toggleStages);
    return () => window.removeEventListener('resize', toggleStages);
  }, [])
  
  function unwrapLiList(list) {
    return list.match(/<li[^>]*>(.*?)<\/li>/g);
  }
  
  function createSolarItem(id, price, name, listPlus, listMinus, link) {
    let obj = {
      id,
      currency: "$",
      price,
      name,
      list: [],
      link
    };

    listPlus.map((plus) => {
      return obj.list.push({
        title: plus.match(/<li[^>]*>(.*?)<\/li>/)[1],
        type: "+"
      });
    });

    listMinus.map((minus) => {
      return obj.list.push({
        title: minus.match(/<li[^>]*>(.*?)<\/li>/)[1],
        type: "-"
      });
    })

    return obj;
  }
  
  const solarPowerContent = [
    createSolarItem(
      0,
      data.solar.edges[0].node.econom_price,
      data.solar.edges[0].node.econom_title_ua,
      unwrapLiList(data.solar.edges[0].node.econom_list_ua),
      unwrapLiList(data.solar.edges[0].node.econom_list_minus_ua),
      "/services-business"
    ),
    createSolarItem(
      1,
      data.solar.edges[0].node.reserve_price,
      data.solar.edges[0].node.reserve_title_ua,
      unwrapLiList(data.solar.edges[0].node.reserve_list_ua),
      unwrapLiList(data.solar.edges[0].node.reverse_list_minus_ua),
      "/services-reserve"
    ),
    createSolarItem(
      2,
      data.solar.edges[0].node.auto_price,
      data.solar.edges[0].node.auto_title_ua,
      unwrapLiList(data.solar.edges[0].node.auto_list_ua),
      unwrapLiList(data.solar.edges[0].node.auto_list_minus_ua),
      "/services-auto"
    )
  ]
  
  return (
    <Layout local={indexLocal} location={location}>
      <SEO title={data.seo.title_ua} description={data.seo.description_ua} lang='ua' />
      <FirstScreen slides={data.slider.edges} />
      <WhyUs
        list={data.allDirectusWhyIsList.edges[0].node}
        icons={data.allDirectusWhyUsIconsRu.edges}
      />
      { stages }
      <CompletedProjects projects={data.projects.edges} />
      <SolarPowerPlants content={solarPowerContent}/>
      <SolarPowerPlantsSmall content={solarPowerContent}/>
      <OurClients clients={data.clients.edges} />
      <FormOrder withMap={true} background="white" wave="gray"/>
    </Layout>
  )
  
}

export default IndexPage